import { Auth } from 'aws-amplify';
import bouncerToken from './bouncerToken';

const getAuthTokenHelper = async () => {
  const { bouncerEnabled } = bouncerToken.getBouncerProps();
  let token = '';
  if (bouncerEnabled) {
    token = await bouncerToken.getToken();
  } else {
    const session = await Auth.currentSession();
    if (!session.isValid()) {
      await Auth.signOut();
    }
    token = session.idToken.jwtToken;
  }

  return token;
};

export default getAuthTokenHelper;
