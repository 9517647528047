import React from 'react';
import { SignIn, ForgotPassword } from 'aws-amplify-react';

// HACK:
// Needed to use different import for these UI-component as they are
// not defined as exports within the global scope of the component.
import {
  FormSection,
  SectionBody,
  Link,
  FormField,
  FormRow,
  Input,
  InputLabel,
  Hint,
  SectionFooter,
  SectionFooterPrimaryContent,
  Button
} from 'aws-amplify-react/dist/Amplify-UI/Amplify-UI-Components-React';
import { Auth, I18n, Logger } from 'aws-amplify';
import { version } from '../../../package.json';
import { buildNumber } from '../constants';
import elligoLogo from '../assets/Elligo-IntElligo-Logo-Research-Stack-Screen.png';

const verboseMessage1 =
  "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+";

const verboseMessage2Part1 =
  "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+";
const verboseMessage2Part2 =
  "Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+";

const verboseMessage2 = `${verboseMessage2Part1}; ${verboseMessage2Part2}`;

const translatedMessage1 = 'Invalid username';
const translatedMessage2 = 'Incorrect username or password';

I18n.putVocabularies({
  en: {
    [verboseMessage1]: [translatedMessage1],
    [verboseMessage2]: [translatedMessage2]
  }
});

const logger = new Logger('SignIn');

export default class Login extends SignIn {
  componentDidMount() {
    const buildInfo = () => {
      const url = window.location.href;
      if (url.includes('localhost')) {
        return `LOCAL ${version}.${buildNumber}`;
      }
      if (url.includes('dev')) {
        return `DEV ${version}.${buildNumber}`;
      }
      if (url.includes('qa')) {
        return `QA ${version}.${buildNumber}`;
      }
      if (url.includes('staging')) {
        return `STAGE ${version}.${buildNumber}`;
      }
      if (url.includes('sandbox')) {
        return `SANDBOX ${version}`;
      }
      if (url.includes('training')) {
        return `TRAIN ${version}`;
      }
      // if production, just give the version
      return `${version}`;
    };
    this.setState({ buildInfo });
    // display the output here, but using the function in state, so that way
    // we print the information to the console only once
    console.log(`Elligo Central, Version ${version}.${buildNumber}`);
  }

  async signIn(event) {
    // avoid submitting the form
    if (event) {
      event.preventDefault();
    }

    const username = this.getUsernameFromInput() || '';
    const { password } = this.inputs;

    if (!Auth || typeof Auth.signIn !== 'function') {
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported'
      );
    }
    this.setState({ loading: true });
    try {
      const user = await Auth.signIn(username.trim().toLowerCase(), password);
      logger.debug(user);
      if (
        user.challengeName === 'SMS_MFA' ||
        user.challengeName === 'SOFTWARE_TOKEN_MFA'
      ) {
        logger.debug(`confirm user with ${user.challengeName}`);
        this.changeState('confirmSignIn', user);
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        logger.debug('require new password', user.challengeParam);
        this.changeState('requireNewPassword', user);
      } else if (user.challengeName === 'MFA_SETUP') {
        logger.debug('TOTP setup', user.challengeParam);
        this.changeState('TOTPSetup', user);
      } else if (
        user.challengeName === 'CUSTOM_CHALLENGE' &&
        user.challengeParam &&
        user.challengeParam.trigger === 'true'
      ) {
        logger.debug('custom challenge', user.challengeParam);
        this.changeState('customConfirmSignIn', user);
      } else {
        this.checkContact(user);
      }
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        logger.debug('the user is not confirmed');
        this.changeState('confirmSignUp', { username });
      } else if (err.code === 'PasswordResetRequiredException') {
        logger.debug('the user requires a new password');
        this.changeState('forgotPassword', { username });
      } else {
        this.error(err);
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  onKeyDown(e) {
    if (e.keyCode !== 13) return;

    const { hide = [] } = this.props;
    if (this.props.authState === 'signIn' && !hide.includes(Login)) {
      this.signIn();
    }
  }

  showComponent(theme) {
    const { hide = [], override = [] } = this.props;
    if (hide && hide.includes(Login)) {
      return null;
    }

    const hideForgotPassword =
      !override.includes('ForgotPassword') &&
      hide.some(component => component === ForgotPassword);
    return (
      <FormSection theme={theme}>
        <div className="pb-3" style={{ textAlign: 'center' }}>
          <img
            src={elligoLogo}
            style={{ height: '130px' }}
            alt="Intelligo Logo"
          />
        </div>
        <SectionBody theme={theme}>
          <FormField theme={theme}>
            <InputLabel theme={theme}>{I18n.get('Username')}*</InputLabel>
            <Input
              autoFocus
              placeholder={I18n.get('Enter your username')}
              theme={theme}
              key="username"
              name="username"
              onChange={this.handleInputChange}
            />
          </FormField>
          <FormField theme={theme}>
            <InputLabel theme={theme}>{I18n.get('Password')} *</InputLabel>
            <Input
              placeholder={I18n.get('Enter your password')}
              theme={theme}
              key="password"
              type="password"
              name="password"
              onChange={this.handleInputChange}
            />
            {!hideForgotPassword && (
              <Hint theme={theme}>
                {I18n.get('Forget your password? ')}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  theme={theme}
                  onClick={() => this.changeState('forgotPassword')}
                >
                  {I18n.get('Reset password')}
                </Link>
              </Hint>
            )}
          </FormField>
        </SectionBody>
        <SectionFooter theme={theme}>
          <SectionFooterPrimaryContent theme={theme}>
            <Button
              theme={theme}
              onClick={this.signIn}
              disabled={this.state.loading}
            >
              {I18n.get('Sign In')}
            </Button>
          </SectionFooterPrimaryContent>
        </SectionFooter>
        <FormRow>
          <p className="version">
            {this.state.buildInfo()} &copy; {new Date().getFullYear()} Elligo
            Health Research, Inc. All Rights Reserved
          </p>
        </FormRow>
      </FormSection>
    );
  }
}
