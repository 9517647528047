import React from 'react';
import { withBouncer } from 'bouncer';
import { connect } from 'react-redux';
import { ForgotPassword, withAuthenticator } from 'aws-amplify-react';
import PropTypes from 'prop-types';
import bouncerToken from '../fetchers/bouncerToken';
import CustomNewPassword from '../components/CustomNewPassword';
import Login from '../components/Login';
import backgroundImage from '../assets/login-background-custom.jpg';

const CustomTheme = {
  container: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'contain',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center'
  },
  formContainer: {
    marginTop: '5%',
    marginBottom: '0'
  },
  button: {
    backgroundColor: '#691e44'
  }
};

const WithBouncerOrAmplify = ({ component, ...rest }) => {
  let LoginComponent;
  const bouncerProps = bouncerToken.getBouncerProps();

  let additionalProps = null;
  if (bouncerProps.domain) {
    additionalProps = { domain: bouncerProps.domain };
  }

  if (bouncerProps && bouncerProps.bouncerEnabled) {
    LoginComponent = withBouncer(
      connect(null)(component),
      bouncerProps.clientId,
      bouncerProps.audience,
      bouncerProps.connection,
      window.location.origin,
      false,
      async getAccessToken => {
        bouncerToken.setGetAuth0AccessToken(getAccessToken);
      },
      null,
      additionalProps
    );
  } else {
    LoginComponent = withAuthenticator(
      connect(null)(component),
      false,
      [<Login />, <ForgotPassword />, <CustomNewPassword />],
      null,
      CustomTheme
    );
  }
  return <LoginComponent {...rest} />;
};

WithBouncerOrAmplify.propTypes = {
  component: PropTypes.elementType.isRequired
};

WithBouncerOrAmplify.defaultProps = {};

export default WithBouncerOrAmplify;
