import Amplify from 'aws-amplify';
import bouncerToken from './fetchers/bouncerToken';
import featureFlags from './fetchers/featureFlags';

function configure(config) {
  if (config.bouncerEnabled) {
    bouncerToken.setBouncerProps(config.Bouncer);
  } else {
    Amplify.configure({
      Auth: config.Amplify
    });
  }
  featureFlags.setFeatureFlags({
    studyMarketplaceRolesManagement: config.studyMarketplaceRolesManagement,
    bouncerEnabled: config.bouncerEnabled,
    mandatorySignIn: config.mandatorySignIn
  });
}

const initPromise = fetch('/authinfo')
  .then(resp => resp.json())
  .then(response => {
    return {
      bouncerEnabled: response.bouncerEnabled,
      Amplify: {
        userPoolId: response.userPoolId,
        userPoolWebClientId: response.userPoolWebClientId,
        region: response.region,
        mandatorySignIn: true
      },
      Bouncer: {
        bouncerEnabled: response.bouncerEnabled,
        audience: response.audience,
        domain: response.domain,
        clientId: response.clientId,
        connection: response.connection
      },
      studyMarketplaceRolesManagement: response.studyMarketplaceRolesManagement
    };
  })
  .then(configure);

async function authConfig() {
  return initPromise;
}

export default authConfig;
