import React from 'react';
import { I18n } from 'aws-amplify';
import { RequireNewPassword } from 'aws-amplify-react';

import {
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Input,
  Button,
  Link,
  SectionFooterPrimaryContent,
  SectionFooterSecondaryContent
} from 'aws-amplify-react/dist/Amplify-UI/Amplify-UI-Components-React';

function convertToPlaceholder(str) {
  return str
    .split('_')
    .map(part => part.charAt(0).toUpperCase() + part.substr(1).toLowerCase())
    .join(' ');
}

class CustomNewPassword extends RequireNewPassword {
  showComponent(theme) {
    const { hide } = this.props;
    if (hide && hide.includes(CustomNewPassword)) {
      return null;
    }

    const user = this.props.authData;
    const { requiredAttributes } = user.challengeParam;

    return (
      <FormSection theme={theme}>
        <SectionHeader theme={theme}>
          {I18n.get('Change Password')}
        </SectionHeader>
        <SectionBody theme={theme}>
          <Input
            autoFocus
            placeholder={I18n.get('New Password')}
            theme={theme}
            key="password"
            name="password"
            type="password"
            onChange={this.handleInputChange}
          />

          {requiredAttributes.map(attribute => {
            let placeholder;
            if (I18n.get(convertToPlaceholder(attribute)) === 'Family Name') {
              placeholder = 'Last Name';
            } else if (
              I18n.get(convertToPlaceholder(attribute)) === 'Given Name'
            ) {
              placeholder = 'First Name';
            } else {
              placeholder = I18n.get(convertToPlaceholder(attribute));
            }
            return (
              <Input
                placeholder={placeholder}
                theme={theme}
                key={attribute}
                name={attribute}
                type="text"
                onChange={this.handleInputChange}
              />
            );
          })}
        </SectionBody>
        <SectionFooter theme={theme}>
          <SectionFooterPrimaryContent theme={theme}>
            <Button theme={theme} onClick={this.change}>
              {I18n.get('Change')}
            </Button>
          </SectionFooterPrimaryContent>
          <SectionFooterSecondaryContent theme={theme}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link theme={theme} onClick={() => this.changeState('signIn')}>
              {I18n.get('Back to Sign In')}
            </Link>
          </SectionFooterSecondaryContent>
        </SectionFooter>
      </FormSection>
    );
  }
}

export default CustomNewPassword;
